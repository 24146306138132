var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infobox" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "货运基本条例",
          "left-arrow": "",
          fixed: "",
          placeholder: ""
        },
        on: { "click-left": _vm.onClickLeft }
      }),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.center) } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }